import { createClient } from '@supabase/supabase-js'

let supabaseInstance = null;

const createSupabaseClient = (token) => {
   if (supabaseInstance) {
      if (token) {
         supabaseInstance.headers.Authorization = `Bearer ${token}`
      }
      return supabaseInstance;
   }

   const supabaseClient = createClient(
      process.env.NEXT_PUBLIC_SUPABASE_URL,
      process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
   )

   if (token) {
      supabaseClient.headers.Authorization = `Bearer ${token}`
   }

   supabaseInstance = supabaseClient;
   return supabaseInstance;
}

export default createSupabaseClient