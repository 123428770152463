import Link from 'next/link'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

const baseStyles = {
   solid: 'group inline-flex items-center justify-center py-2 px-4 text-sm font-medium focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 transition duration-200 ease-in-out',
   outline:
      'group inline-flex ring-1 items-center justify-center py-2 px-4 text-sm focus:outline-none transition duration-200 ease-in-out',
}

const variantStyles = {
   solid: {
      slate: 'bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900',
      blue: 'bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600',
      white: 'bg-white text-black hover:bg-gray-200 focus:ring-gray-300',
      glassWhite:
         'bg-white/10 backdrop-md text-white backdrop-blur-md hover:bg-white/20 focus:ring-gray-300',
      primary:
         'bg-primary-800 text-white hover:bg-primary-700 active:bg-primary-700 focus-visible:outline-primary-500',
      secondary:
         'bg-secondary-400 text-white hover:bg-secondary-500 active:bg-secondary-400 focus-visible:outline-secondary-500',
      green: 'bg-green-600 text-white hover:bg-green-700 active:bg-green-700 focus-visible:outline-green-500',
      cyan:
         'bg-[#00f7f0] text-black hover:bg-[#00f7f0] active:bg-[#00f7f0] focus-visible:outline-[#00f7f0]',
      discord:
         'bg-[#5865f2] text-white hover:bg-[#5f73c1] active:bg-[#5865f2] focus-visible:outline-[#7289da]',
      red: 'bg-red-600 text-white hover:bg-red-700 active:bg-red-700 focus-visible:outline-red-500',
      dark: 'bg-neutral-900 text-white hover:bg-neutral-800 active:bg-neutral-800 focus-visible:outline-neutral-700',
   },
   outline: {
      slate: 'ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300',
      white: 'ring-neutral-800 text-white hover:ring-white/30 active:ring-white/10 active:text-white/30 focus-visible:outline-white',
      cyan: 'ring-[#00f7f0]/20 text-white hover:ring-[#00f7f0] active:ring-[#00f7f0]/10 active:text-[#00f7f0]/30 focus-visible:outline-[#00f7f0]',
   },
}

const sizes = {
   sm: 'px-2 py-1 text-xs',
   md: 'px-4 py-2 text-sm',
   lg: 'px-6 py-3 text-base',
   xl: 'px-8 py-4 text-lg',
}
const ButtonCustom = ({ variant = 'solid', color = 'primary', className = '', href = '', size = 'md', isLoading = false, ...props }) => {
   className = twMerge(
      baseStyles[variant],
      variantStyles[variant][color],
      className,
      sizes[size],
      isLoading || props.disabled
         ? 'bg-primaryDark-800 hover:bg-primaryDark-800 active:bg-primaryDark-800 cursor-not-allowed'
         : ''
   )

   props.children = isLoading ? (
      <div className={'flex items-center'}>
         <div className={'animate-spin mr-3 rounded-full h-5 w-5 border-b-2 border-white'} />
         {props.children}
      </div>
   ) : (
      props.children
   )

   return href ? (
      <Link href={href} className={className} {...props} />
   ) : (
      <button className={className} {...props} />
   )
}

ButtonCustom.propTypes = {
   variant: PropTypes.oneOf(['solid', 'outline']),
   color: PropTypes.oneOf(['slate', 'blue', 'white', 'primary', 'secondary', 'cyan', 'discord']),
   size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
   className: PropTypes.string,
   href: PropTypes.string,
}

export default ButtonCustom