import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettings } from '@/redux/settingsSlice'

const useSelectedWallet = () => {
   const dispatch = useDispatch()
   const selectSelectedWallet = createSelector(
      (state) => state.settings,
      (settings) => settings.selectedWallet
   )
   const selectedWallet = useSelector(selectSelectedWallet)

   const updateSelectedWallet = (newValue) => {
      dispatch(
         updateSettings({
            selectedWallet: newValue,
         })
      )
   }

   return [selectedWallet, updateSelectedWallet]
}

export default useSelectedWallet
