import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { updateSettings } from '@/redux/settingsSlice'

const useWallets = () => {
   const dispatch = useDispatch()
   const selectWallets = createSelector(
      (state) => state.settings,
      (settings) => settings.wallets
   )
   const wallets = useSelector(selectWallets)

   const addWallet = (wallet) => {
      dispatch(
         updateSettings({
            wallets: {
               ...wallets,
               [wallet.address]: {
                  ...wallet,
               },
            },
         })
      )
   }

   const removeWallet = (address) => {
      const newWallets = { ...wallets }
      delete newWallets[address]
      dispatch(
         updateSettings({
            wallets: newWallets,
         })
      )
   }

   const resetWallets = () => {
      dispatch(
         updateSettings({
            wallets: {},
         })
      )
   }

   return {
      wallets,
      addWallet,
      removeWallet,
      resetWallets,
   }
}

export default useWallets
