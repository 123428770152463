import ButtonCustom from '@/components/Button'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import TextInput from '../TextInput'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import Link from 'next/link'
import Image from 'next/image'

export const AddWalletModel = ({
   open,
   onClose,
   onLoginWithMetamask,
   onLoginWithSignedMessage,
}) => {
   const [loginWithSignedMessage, setLoginWithSignedMessage] = useState(false)
   const [signatureHash, setSignatureHash] = useState('')
   const [address, setAddress] = useState('')

   useEffect(() => {
      if (!open) {
         setLoginWithSignedMessage(false)
         setSignatureHash('')
         setAddress('')
      }
   }, [open])

   return (
      <Transition.Root show={open} as={Fragment}>
         <Dialog as="div" className="relative z-10" onClose={onClose}>
            <Transition.Child
               as={Fragment}
               enter="ease-out duration-300"
               enterFrom="opacity-0"
               enterTo="opacity-100"
               leave="ease-in duration-200"
               leaveFrom="opacity-100"
               leaveTo="opacity-0"
            >
               <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
               <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                     as={Fragment}
                     enter="ease-out duration-300"
                     enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                     enterTo="opacity-100 translate-y-0 sm:scale-100"
                     leave="ease-in duration-200"
                     leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                     leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                     <Dialog.Panel className="relative transform   bg-white/10 border border-white/20 backdrop-blur-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                        <div className="flex items-center text-white mb-2 space-x-2">
                           {loginWithSignedMessage ? (
                              <ChevronLeftIcon
                                 className={'w-6 h-6 cursor-pointer'}
                                 onClick={() => setLoginWithSignedMessage(false)}
                              />
                           ) : null}
                           <div>
                              <h2 className={'text-2xl font-bold'}>Add Wallet</h2>
                           </div>
                        </div>

                        {loginWithSignedMessage ? (
                           <div className="flex flex-col w-full space-y-4 pt-4">
                              <div>
                                 <label
                                    htmlFor="address"
                                    className="block text-sm font-medium text-white mb-2"
                                 >
                                    Address
                                 </label>
                                 <TextInput
                                    name={'address'}
                                    placeholder={'0x...'}
                                    className={'w-full'}
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                 />
                              </div>
                              <div>
                                 <label
                                    htmlFor="signatureHash"
                                    className="block text-sm font-medium text-white mb-2"
                                 >
                                    Signature hash
                                 </label>
                                 <TextInput
                                    name={'signatureHash'}
                                    placeholder={'Enter signature hash with message "web3me"'}
                                    className={'w-full'}
                                    value={signatureHash}
                                    onChange={(e) => setSignatureHash(e.target.value)}
                                 />
                                 <p className={'text-sm text-gray-300 mt-1.5'}>
                                    Sign a message from
                                    <Link
                                       href="https://etherscan.io/verifiedSignatures"
                                       target="_blank"
                                       rel="noreferrer"
                                       className="text-primary-400 font-bold mx-1 underline"
                                    >
                                       Etherscan Verified Signatures
                                    </Link>
                                    {`with message "web3me" and paste the signature hash here.`}
                                 </p>
                              </div>
                              <ButtonCustom
                                 size="lg"
                                 onClick={() => onLoginWithSignedMessage(signatureHash, address)}
                                 disabled={signatureHash.length !== 132 || address.length !== 42}
                              >
                                 Add Wallet
                              </ButtonCustom>
                           </div>
                        ) : (
                           <div className="flex flex-col w-full space-y-4 pb-2 pt-4">
                              <ButtonCustom color={'white'} size="lg" onClick={onLoginWithMetamask}>
                                 <Image
                                    src="/images/MetaMask.png"
                                    alt="MetaMask"
                                    width={24}
                                    height={24}
                                    className="w-6 h-6 mr-2"
                                 />
                                 Login with Metamask
                              </ButtonCustom>
                           </div>
                        )}
                     </Dialog.Panel>
                  </Transition.Child>
               </div>
            </div>
         </Dialog>
      </Transition.Root>
   )
}