import React, {useState} from 'react'
import { twMerge } from 'tailwind-merge'
import Image from 'next/image';

const TextInput = React.forwardRef(
   ({ name, type, label, required, className, hint, errorMessage, icon, ...rest }, ref) => {
      const globalClasses = `bg-neutral-900 border-none ring-0  w-full text-white placeholder:text-neutral-500 text-sm`
      const searchClass = `bg-transparent border-none ring-0  w-full text-white placeholder:text-neutral-500 text-sm`
      const externalClass = `bg-[#182332] border-none ring-0 rounded-lg w-full text-white placeholder:text-neutral-500 text-sm`

      const maxLength = 500
      const [text, setText] = useState('');

      const handleTextChange = (event) => {
          setText(event.target.value);
      };
      
      if (type === 'textarea') {
         return (
            <div>
               {label && (
                  <label htmlFor={name} className="block text-sm font-medium text-white mb-2">
                     {label}
                     {required && <span className="text-red-400">*</span>}
                  </label>
               )}
               {errorMessage && (
                  <p className="mb-2.5 text-xs leading-5 text-red-400">{errorMessage}</p>
               )}
               <textarea
                  ref={ref}
                  name={name}
                  className={twMerge(
                     'h-24',
                     globalClasses,
                     className,
                     errorMessage ? '' : ''
                  )}
                  style={{
                     '--tw-ring-color': 'none',
                  }}
                  {...rest}
               />
               {hint && <p className="mt-2.5 text-xs leading-5 text-neutral-400">{hint}</p>}
            </div>
         )
      }
      
      if (type === 'textarea_external') {
         return (
            <div>
               {label && (
                  <label htmlFor={name} className="block text-sm font-medium text-white mb-2">
                     {label}
                     {required && <span className="text-red-400">*</span>}
                  </label>
               )}
               {errorMessage && (
                  <p className="mb-2.5 text-xs leading-5 text-red-400">{errorMessage}</p>
               )}
               <textarea
                  ref={ref}
                  name={name}
                  className={twMerge(
                     'h-24',
                     externalClass,
                     className,
                     errorMessage ? '' : ''
                  )}
                  onChange={handleTextChange}
                  value={text}
                  maxLength={maxLength}
                  {...rest}
               />
               {hint && <p className="mt-2.5 text-xs leading-5 text-neutral-400">{hint}</p>}
               <div className="text-right text-xs text-neutral-400">
                {`${text.length}/${maxLength} characters`}
               </div>
            </div>
         )
      }
      
      if (type === 'external') {
         return (
            <div>
               {label && (
                  <label htmlFor={name} className="block text-sm font-medium text-white mb-2">
                     {label}
                     {required && <span className="text-red-400">*</span>}
                  </label>
               )}
               {errorMessage && (
                  <p className="mb-2.5 text-xs leading-5 text-red-400">{errorMessage}</p>
               )}
               <input
                  ref={ref}
                  name={name}
                  className={twMerge(
                     externalClass,
                     className,
                     errorMessage ? '' : ''
                  )}
                  style={{
                     '--tw-ring-color': 'none',
                  }}
                  {...rest}
               />
               {hint && <p className="mt-2.5 text-xs leading-5 text-neutral-400">{hint}</p>}
            </div>
         )
      }
      
      if (type === 'search') {
         return (
            <div
               className={twMerge(
                  'flex grow items-center h-[3rem] overflow-hidden border border-neutral-900 ring-0  text-white placeholder:text-neutral-500 text-sm',
                  className
               )}
            >
               <Image
                  src={'/images/search.png'}
                  alt={'search'}
                  className={'w-[2rem] sm:w-[2.5rem] object-contain ml-1 color-white'}
               />
               <input
                  ref={ref}
                  name={name}
                  className={twMerge(searchClass, 'mb-0.5 -ml-2 sm:-ml-1')}
                  style={{
                     '--tw-ring-color': 'none',
                  }}
                  {...rest}
               />
            </div>
         )
      }
      
      return (
         <div className={'w-full'}>
            {label && (
               <label htmlFor={name} className="block text-sm font-medium text-white mb-2">
                  {label}
                  {required && <span className="text-red-400">*</span>}
               </label>
            )}
            {errorMessage && (
               <p className="mb-2.5 text-xs leading-5 text-red-400">{errorMessage}</p>
            )}
            <input
               ref={ref}
               type={type || 'text'}
               name={name}
               className={twMerge(
                  globalClasses,
                  className,
                  errorMessage ? '' : '',
                  'h-[3rem]'
               )}
               {...rest}
            />
            {hint && <p className="mt-2.5 text-xs leading-5 text-neutral-400">{hint}</p>}
         </div>
      )
   }
)

// Add display name to the component
TextInput.displayName = 'TextInput';

export default TextInput