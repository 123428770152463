import shortAddress from '@/utils/helpers/shortAddress'
import { TrashIcon } from '@heroicons/react/24/outline'
import { twMerge } from 'tailwind-merge'

export const MyWalletCard = ({ address, onRemoveWallet, selected, onClick }) => {
   return (
      <div
         className={twMerge(
            'flex items-center justify-between space-x-1 ',
            selected ? 'bg-neutral-800' : 'hover:bg-neutral-700 cursor-pointer'
         )}
         onClick={onClick}
      >
         <div className={'flex items-center p-2 grow  duration-200 transition-colors'}>
            <p className={'text-sm text-white ml-2'}>{shortAddress(address)}</p>
         </div>
         <div
            onClick={onRemoveWallet}
            className={'flex p-2 hover:bg-white/20  cursor-pointer duration-200 transition-colors'}
         >
            <TrashIcon className={'w-[20px] h-[20px] text-gray-300'} />
         </div>
      </div>
   )
}
