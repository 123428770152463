import useWallets from '@/hooks/useWallets'
import shortAddress from '@/utils/helpers/shortAddress'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { signOut, useSession } from 'next-auth/react'
import { Fragment, useEffect, useMemo, useState, forwardRef, useImperativeHandle } from 'react'
import { useDisconnect } from 'wagmi'
import { MyWalletCard } from './MyWalletCard'
import { toast } from 'react-toastify'
import useSelectedWallet from '@/hooks/useSelectedWallet'
import { AddWalletModel } from './AddWalletModel'
import { recoverMessageAddress } from 'viem'
import { generateAvatarURL } from '@cfx-kit/wallet-avatar'
import Image from 'next/image'


const ConnectedWallet = forwardRef((props, ref) => {
   const { data: session } = useSession()
   const { wallets, removeWallet, addWallet } = useWallets()
   const { disconnect } = useDisconnect()
   const { openConnectModal } = useConnectModal()
   const [openAddWalletModal, setOpenAddWalletModal] = useState(false)

   const [selectedWallet, updateSelectedWallet] = useSelectedWallet()
   const connectedWallets = useMemo(() => Object.values(wallets), [wallets])
   const userImage = useMemo(
      () =>
         props.cvs?.find((cv) => cv.photo && cv.owner === selectedWallet)?.photo ||
         (selectedWallet && generateAvatarURL(selectedWallet)),
      [props.cvs, selectedWallet]
   )

   useEffect(() => {
      /**
       * If there is no selected wallet, select the first wallet
       *  If there is a selected wallet, but it is not in the connected wallets, select the first wallet
       * If there is a selected wallet, and it is in the connected wallets, do nothing
       */
      if (
         (!selectedWallet && connectedWallets.length > 0) ||
         (connectedWallets.length >= 1 &&
            !connectedWallets.some((wallet) => wallet.address === selectedWallet))
      ) {
         updateSelectedWallet(connectedWallets[0].address)
      }
   }, [selectedWallet, wallets, connectedWallets, updateSelectedWallet])

   const handleRemoveWallet = async (address) => {
      removeWallet(address)
      if (address === selectedWallet) {
         await signOut();
         localStorage.clear(); 
      }
   }

   const handleLoginWithMetaMask = () => {
      if (connectedWallets.length > 0) {
         toast.error('Only one wallet can be connected')
         return
      }
      openConnectModal()
      setOpenAddWalletModal(false)
   }

   const handleLoginWithSignedMessage = async (signature, address) => {
      if (connectedWallets.length > 0) {
         toast.error('Only one wallet can be connected')
         return
      }

      const recoveredAddress = await recoverMessageAddress({
         message: 'web3me',
         signature,
      })

      if (recoveredAddress.toLowerCase() !== address.toLowerCase()) {
         toast.error('Invalid signature')
         return
      }

      addWallet({
         address,
         signature,
      })
      setOpenAddWalletModal(false)
   }

   const handleSelectWallet = (address) => {
      updateSelectedWallet(address)
   }

   // Expose methods to parent component
   useImperativeHandle(ref, () => ({
      getWalletAddress: () => selectedWallet,
   }));

   return (
      <div>
         <AddWalletModel
            open={openAddWalletModal}
            onClose={() => setOpenAddWalletModal(false)}
            onLoginWithMetamask={handleLoginWithMetaMask}
            onLoginWithSignedMessage={handleLoginWithSignedMessage}
         />

         <Popover className="relative">
            <Popover.Button className={'focus-visible:outline-none'}>
               <div
                  className={
                     'flex flex-row items-center rounded-full border font-semibold border-neutral-800 px-4 h-[3.2rem] active:outline-none'
                  }
               >
                  {selectedWallet && (
                     <Image src={userImage} alt={selectedWallet} className="w-8 h-8 rounded-full" width={32} height={32} />
                  )}
                  <p className={'text-sm text-white ml-3 hidden'}>
                     {selectedWallet ? shortAddress(selectedWallet) : ''}
                  </p>
                  <ChevronDownIcon className={'w-4 h-4 ml-2.5 text-white'} />
               </div>
            </Popover.Button>
            <Transition
               as={Fragment}
               enter="transition ease-out duration-200"
               enterFrom="opacity-0 translate-y-1"
               enterTo="opacity-100 translate-y-0"
               leave="transition ease-in duration-150"
               leaveFrom="opacity-100 translate-y-0"
               leaveTo="opacity-0 translate-y-1"
            >
               <Popover.Panel className="absolute left-3/4 z-[9999] mt-5 flex w-screen max-w-min -translate-x-3/4 px-4">
                  <div className="w-64 shrink rounded-xl bg-black border border-neutral-800 p-4 text-sm font-semibold leading-6 text-white shadow-lg">
                     <p className={'text-sm text-gray-200 mb-2'}>My Wallets</p>
                     {connectedWallets.map((wallet) => (
                        <MyWalletCard
                           key={wallet.address}
                           address={wallet.address}
                           onRemoveWallet={() => handleRemoveWallet(wallet.address)}
                           selected={selectedWallet === wallet.address}
                           onClick={() => handleSelectWallet(wallet.address)}
                        />
                     ))}

                     {/*  <div className={'border-t border-primaryDark-700 mt-3'}>
                        <div
                           className={
                              'flex items-center space-x-1 pt-4 pb-1 cursor-pointer text-gray-30 hover:text-gray-200'
                           }
                           onClick={() => setOpenAddWalletModal(true)}
                        >
                         <PlusIcon className={'w-5 h-5 text-gray-300'} />
                           <p className={'text-sm 0'}>Add Wallet</p>
                        </div>
                     </div>*/}
                  </div>
               </Popover.Panel>
            </Transition>
         </Popover>
      </div>
   )
})

ConnectedWallet.displayName = 'ConnectedWallet';

export default ConnectedWallet